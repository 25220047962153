<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{maxinfo.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{maxinfo.title}}</div>
      <div class="gohome" @click="$router.push('/home')">
        <img src="../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
      </div>

    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad"
        style="display: flex; flex-wrap: wrap;justify-content: space-between; width: 78rem;">
        <div class="evebox" v-for="item in list" @click="gothreeroom(item)">
          <img :src="$image+item.image" class="eveimgbox" alt="">
          <div class="eightbox">
            <div class="textbox">
              <div>
                {{item.name}}
              </div>
              <div class="detailtextbox">
                {{item.info}}
              </div>
            </div>
            <div class="yybox" @click.stop="openpop()">
              立即预约
            </div>
          </div>

        </div>
      </van-list>
    </div>

    <!-- 弹窗 -->
    <van-popup v-model="show" style="width: 49.1rem;height: 32.7rem;background-color: rgba(0, 0, 0, 0);">
      <div class="weizhibox">
        <div class="closebox" @click="closepop()">
          <img src="../../src/newimg/noback.png" style="width: 1.9rem;height: 1.9rem;" alt="">
        </div>
      </div>

      <div style="width: 42.5rem;height:27.5rem;position: relative;background-color: #ffffff;border-radius: 2rem;">
        <div class="backimg">
          <div style="margin-left: 20rem;padding-top: 5.08rem;">智有志心理健康</div>
          <div
            style="margin-left: 20rem;margin-top: 1.65rem;letter-spacing: 1rem;font-size: 1.1111rem;color: rgba(255, 255, 255, 0.3);">
            RESERVATION</div>
        </div>
        <div class="phonemaxbox">
          <img src="../../src/newimg/popphone.png" style="width: 1.5rem;height: 2rem;" alt="">
          <div style="margin-left: 1.15rem;font-size: 1.8rem;letter-spacing: 0.1rem;color: #332323;">{{namelist.mobile}}
          </div>
        </div>
        <div class="phonezuo">
          <img src="../../src/newimg/popzuo.png" style="width: 1.65rem;height: 1.55rem;" alt="">
          <div style="margin-left: 1.15rem;font-size: 1.8rem;letter-spacing: 0.1rem;color: #332323;">{{namelist.phone}}
          </div>
        </div>
        <div class="zhiyouzhi">
          DAI'S ZHIYOUZHI MENTAL HEALTH SELF SERVICE SYSTEM
        </div>

        <div class="qcodebox">
          <img :src="$image+namelist.qr_code" style="width: 12.5rem;height: 12.5rem;" alt="">
        </div>

      </div>

    </van-popup>
  </div>
</template>

<script>
  import {
    getConsultAPI,
    GetphoneAPI
  } from "@/api/home"
  export default {
    name: "Counseling",
    data() {
      return {
        loading: false,
        finished: false,
        maxinfo: {},
        list: [],
        params: {
          page: 1
        },
        show: false,
        namelist: {}
      }
    },
    created() {
      this.getwx()
    },
    methods: {
      async onLoad() {
        this.loading = true
        await this.getlist()
        this.params.page++
        // 加载状态结束
        this.loading = false
      },

      async getlist() {
        const res = await getConsultAPI(this.params)
        if (res.data.list.length <= 0) {
          this.finished = true
          return
        }
        this.list.push(...res.data.list)
        this.maxinfo = res.data
      },
      gothreeroom(val) {
        this.$router.push({
          path: '/threecounsel',
          query: {
            id: val.id,
          }
        })
      },
      // 预约咨询弹窗
      async getwx() {
        const res = await GetphoneAPI()
        this.namelist = res.data
        // console.log(res)
      },
      openpop() {
        this.show = true
      },
      closepop() {
        this.show = false
      }
    },
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../newimg/homebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .centerbox {
      width: 78rem;
      height: 46rem;
      background-color: #ffffff;
      border-radius: 2rem;
      margin-top: 3.5rem;
      // display: flex;
      // justify-content: space-between;
      padding: 0 6.25rem;
      // flex-wrap: wrap;
      padding-bottom: 7rem;
      // padding-top: 2rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .evebox {
        display: flex;
        align-items: center;
        height: 15rem;
        width: 50%;
        // font-size: 2.222rem;
        margin-top: 4.5rem;



        .eveimgbox {
          width: 11.5rem;
          height: 15rem;
          border-radius: 1rem;
          background-color: #f3d5bb;
        }

        .eightbox {
          width: 20.4rem;
          height: 15rem;
          // background-color: red;
          margin-left: 2.5rem;
          position: relative;

          .yybox {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 9rem;
            height: 2.5rem;
            background: linear-gradient(to right, rgba(75, 74, 192, 1), rgba(235, 189, 231, 1));
            font-size: 1.25rem;
            color: #ffffff;
            border-radius: 1.25rem;
            text-align: center;
            line-height: 2.5rem;
          }

          .textbox {
            width: 20.4rem;
            height: 10rem;
            font-size: 2.08rem;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .detailtextbox {
              margin-top: 1.3rem;
              font-size: 1.25rem;
              color: #332323;
              line-height: 1.8rem;
              color: rgba(51, 35, 35, 1);
            }
          }

        }

      }
    }

    .backimg {
      width: 42.5rem;
      height: 11.5rem;
      background-image: url('../newimg/popback.png');
      background-size: 100% 100%;
      border-radius: 2rem 2rem 0 0;
      font-size: 2.5rem;
      color: #ffffff;
    }

    .phonemaxbox {
      margin-top: 1.8rem;
      margin-left: 20rem;
      display: flex;
      align-items: center;
    }

    .phonezuo {
      margin-top: 1.6rem;
      margin-left: 20rem;
      display: flex;
      align-items: center;
    }

    .zhiyouzhi {
      margin-top: 5.5rem;
      font-size: 0.83rem;
      color: rgba(80, 78, 193, 0.3);
      margin-left: 4.1rem;
      letter-spacing: 0.24rem;
    }

    .qcodebox {
      position: absolute;
      top: 5.25rem;
      left: 4.05rem;
      width: 14rem;
      height: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    }

    .weizhibox {
      width: 49.1rem;
      display: flex;
      justify-content: right;
      height: 4rem;
      margin-bottom: 1.2rem;

      .closebox {
        width: 4rem;

        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 1rem;

        // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
      }
    }

  }
</style>
