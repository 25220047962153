// 取token
export function getToken() {
	return localStorage.getItem('token')
}
// 存token
export function setToken(token) {
	localStorage.setItem('token', token)
}
// 删除token
export function removeToken() {
	localStorage.removeItem('token')
}
