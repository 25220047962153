/* 封装axios用于发送请求 */
import axios from 'axios'
import router from '../router'
import {
  getToken
} from '@/utils/storage'
import {
  Toast
} from 'vant';
// 并且设置了新对象的基地址

const baseUrl = 'http://xlht.dai-shi.com/'
const fallbackUrl = 'https://xlht.dai-shi.com/'
const request = axios.create({
  // baseURL: 'https://www.x.orde.live',
  // 设置超时时间为5秒
  // 意思是说如果发一个请求5秒还没反应就直接报连接超时
  timeout: 200000,
  header: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  }

})
request.defaults.baseURL = baseUrl;

// 添加请求拦截器
request.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // 在发送请求之携带token
    if (getToken()) {
      // config.headers.Authorization = `Bearer ${getToken()}`
      config.headers.token = `${getToken()}`
    }

    return config
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么,取值时少写一个data

    return response.data
  },
  async function(error) {
    // 请求失败时，尝试请求备用地址
    if (error.config && !error.config._retry) {
      error.config._retry = true // 标记为重试
      error.config.baseURL = fallbackUrl // 设置为备用地址
      try {
        // 重新发送请求到备用地址
        return await request(error.config)
      } catch (err) {
        console.log('备用地址请求失败', err)
      }
    }

    console.log(error.response.data.code);
    if (error.response.data.code == 401) {
      router.push('/gologin');
      localStorage.removeItem('token')
      localStorage.removeItem('userinfo')
      Toast(error.response.data.msg)

    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

// 暴露出去
export {
  request,
  baseUrl
}
// export default
