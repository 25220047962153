<template>
  <div class="maxbox">
    <!-- 返回键 -->
    <div class="rightbox">
      <div class="gohome" @click="$router.go(-1)"><img src="../../newimg/noback.png"
          style="width: 1.9rem;height: 1.9rem;" alt=""></div>
    </div>

    <div class="titlebox">
      <div class="inputmax">
        <input type="text" v-model="params.name" class="inputbox" placeholder="请输入您的名字">
        <img src="../../newimg/home/con1.png" style="width: 1.75rem;height: 2.05rem;" alt="">
      </div>
      <div class="inputmax">
        <input type="text" v-model="params.phone" class="inputbox" placeholder="您常用的手机号码">
        <img src="../../newimg/home/con2.png" style="width: 1.5rem;height: 2rem;" alt="">
      </div>
    </div>

    <!--    <div class="titlebox" style="margin-top: 1.35rem;">
      <div class="inputmax" style="width: 20rem;">
        <input type="text" v-model="params.reserve_time" class="inputbox" placeholder="请选择预约日期">
        <img src="../../newimg/home/con3.png" style="width: 1.85rem;height: 1.9rem;" alt="">
      </div>
      <div class="inputmax" style="width: 47rem;">
        <input type="text" v-model="params.address" class="inputbox" placeholder="您的联系地址">
        <img src="../../newimg/home/con4.png" style="width: 1.6rem;height: 2.1rem;" alt="">
      </div>
    </div> -->

    <!-- 留言板 -->
    <div class="messagebox">
      <textarea class="myjianjie" v-model="params.content" placeholder="请输入你的留言"></textarea>
    </div>

    <div class="tjiao">
      <div class="qrcode">
        <img :src="$image+list.list.qr_code" style="width: 6rem;height: 6rem;margin-right: 1.7rem;" alt="">
        <div>
          <div>扫描添加企业微信二维码,</div>
          <div> 进行咨询预约！ </div>
        </div>
      </div>
      <div class="submitbox" @click="submit()">
        发送留言
      </div>
    </div>


  </div>
</template>

<script>
  import {
    Toast
  } from 'vant'
  import {
    setConsultAPI,
    getCondetailAPI
  } from "@/api/home"
  export default {
    data() {
      return {
        params: {
          teacher_id: '',
          content: "",
          name: '',
          phone: '',
          address: '',
          reserve_time: ''
        },
        list: [],
        disable: false
      }
    },
    created() {
      this.params.teacher_id = this.$route.query.id
      this.id = this.$route.query.id
      this.getlist()
    },
    methods: {
      // 获取列表
      async getlist() {
        const params = {
          id: this.id
        }
        const res = await getCondetailAPI(params)
        this.list = res.data
      },
      async submit() {
        if (!this.params.content.trim()) {
          Toast('请先输入您的留言')
          return
        }
        if (this.params.content == "请在此处输入你的留言") {
          return
        }
        if (this.disable) {
          return
        }
        this.disable = true

        const res = await setConsultAPI(this.params)
        Toast(res.msg)
        this.disable = false
        setTimeout(() => {
          this.$router.go(-1)
        }, 3000)
      }
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-right: 6.2rem;
    padding-left: 13.8rem;
    background-color: rgba(251, 248, 244, 1);
    color: rgba(51, 35, 35, 1);

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .rightbox {
      display: flex;
      justify-content: right;
      margin-top: 5.9rem;

      .gohome {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        background-color: #ffffff;
        border-radius: 1rem;

      }
    }

    .titlebox {
      width: 75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4.35rem;

      .inputmax {
        padding: 0 1.5rem;
        width: 33.5rem;
        height: 4rem;
        display: flex;
        background-color: #ffffff;
        align-items: center;
        justify-content: space-between;

        .inputbox {
          border: 0;
          // flex: 1;
          width: 90%;
          font-size: 1.8rem;
        }
      }
    }

    .messagebox {
      width: 68.7rem;
      height: 24.3rem;
      background-color: #ffffff;
      // border-radius: 2rem;
      margin-top: 2rem;
      padding-left: 2.5rem;
      padding-right: 3.8rem;
      padding-top: 2.5rem;
      padding-bottom: 2rem;
      font-size: 1.8rem;
      line-height: 3.33rem;

      .myjianjie {
        width: 68.7rem;
        height: 24.3rem;
        overflow-y: scroll;
        border: 0;

        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }

    .tjiao {
      width: 75rem;
      display: flex;
      // margin-top: 2.65rem;
      // align-items: center;
      justify-content: space-between;
      padding-right: 7.5rem;
      margin-top: 3rem;

      .qrcode {
        display: flex;
        align-items: center;
        font-size: 1.66666rem;
        color: rgba(51, 35, 35, 0.5);
      }

      .submitbox {
        width: 17rem;
        height: 4rem;
        border-radius: 2rem;
        background: linear-gradient(to right, rgba(76, 75, 191, 1), rgba(234, 189, 231, 1));
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        color: #ffffff;
      }
    }


  }
</style>
