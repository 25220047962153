import {
  request
} from "@/utils/require";

// 获取心理课堂分类
export function getClassroomAPI(params) {
  return request({
    url: '/api/menu/menus',
    method: 'get',
    params
  })
}

// 获取心理课堂分类列表
export function getThreeroomAPI(data) {
  return request({
    url: '/api/menu/lists',
    method: 'post',
    data
  })
}

// 获取详情
export function getDetailsAPI(data) {
  return request({
    url: '/api/menu/details',
    method: 'post',
    data
  })
}

// 心理咨询列表
export function getConsultAPI(params) {
  return request({
    url: '/api/menu/consult',
    method: 'get',
    params
  })
}
// 心理咨询详情
export function getCondetailAPI(params) {
  return request({
    url: '/api/menu/consultInfo',
    method: 'get',
    params
  })
}
// 心理咨询预约
export function GetphoneAPI(data) {
  return request({
    url: '/api/index/info',
    method: 'post',
    data
  })
}
// 心理咨询留言
export function setConsultAPI(data) {
  return request({
    url: '/api/menu/messages',
    method: 'post',
    data
  })
}

// 心理影院
export function getVideosAPI(params) {
  return request({
    url: '/api/menu/videos',
    method: 'get',
    params
  })
}
// 心理影院
export function getVideosDetail(params) {
  return request({
    url: '/api/menu/videosInfo',
    method: 'get',
    params
  })
}

// 心理测试
export function getonlineTest(params) {
  return request({
    url: '/api/menu/onlineTest',
    method: 'get',
    params
  })
}
// 心理测试
export function getIdonlineTest(params) {
  return request({
    url: 'api/menu/testInfo',
    method: 'get',
    params
  })
}

// 心理游戏
export function getGamesApi(params) {
  return request({
    url: '/api/menu/games',
    method: 'get',
    params
  })
}
// 心理游戏id
export function getIdGames(params) {
  return request({
    url: '/api/menu/gamesInfo',
    method: 'get',
    params
  })
}
