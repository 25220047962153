<template>
  <!-- 首页 -->
  <div class="home">
    <div style="min-height: 100vh;">
      <div class="firstbox">
        <img src="../newimg/home/logoback.png" style="width: 29.25rem;height: 4rem;" alt="">
        <div class="namebox">
          <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect">
            <template #reference>
              {{username}}
              <img src="../newimg/bottomimg.png" style="width:1rem;height: 1rem;" alt="">
            </template>
          </van-popover>
        </div>
      </div>

      <!-- 内容大盒子 -->
      <div class="theflexbox">

        <!-- 右边五个 -->
        <div class="centerleft">
          <!-- 前两个 -->
          <div class="flexbox">
            <!-- 心理课堂 -->
            <div class="xinlibox1" @click="$router.push('/classroom?type=4')">
              <div style="padding-left: 3.15rem;">心理课堂</div>
              <div class="englishtext" style="padding-left: 3.15rem;">Psychological Classroom</div>
              <div style="text-align: right;margin-top: 3.4rem;margin-right: 3rem;"><img
                  src="../newimg/home/homeico1.png" style="width: 6.15rem;height: 6.1rem;" alt=""></div>
            </div>
            <!--  在线测试-->
            <div class="xinlibox2" @click="$router.push('/classroom?type=8')">
              <div style="padding-left: 4rem;">在线测试</div>
              <div class="englishtext" style="padding-left: 4rem;">Online Testing</div>
              <div style="text-align: right;margin-top: 3.5rem;margin-right: 3.5rem;"><img
                  src="../newimg/home/homeico2.png" style="width: 5.025rem;height: 5.9rem;" alt=""></div>
            </div>
          </div>

          <!-- 3到5 -->
          <div class="flexbox" style="margin-top: 2.2rem;">
            <!-- 心理音乐 -->
            <div class="secbox" @click="$router.push('/music')">
              <div>心理音乐</div>
              <div class="englishtext">Psychological Music</div>
              <img src="../newimg/home/homeico3.png" style="width: 6.1rem;height: 5.6rem;margin-top: 2.65rem;" alt="">
            </div>
            <!-- 心理音乐 -->
            <div class="secbox" style="background-color: rgba(242, 211, 192, 0.3);"
              @click="$router.push('/threeroom?type=9')">
              <div>心理影院</div>
              <div class="englishtext">Psychological Cinema</div>
              <img src="../newimg/home/homeico4.png" style="width: 6rem;height: 6rem;margin-top: 2.5rem;" alt="">
            </div> <!-- 心理音乐 -->
            <div class="secbox" style="background-color: rgba(242, 155, 143, 0.3);"
              @click="$router.push('/threeroom?type=7')">
              <div>心理游戏</div>
              <div class="englishtext">Psychological Game</div>
              <img src="../newimg/home/homeico5.png" style="width: 7.4rem;height: 5.35rem;margin-top: 2.75rem;" alt="">
            </div>
          </div>
        </div>

        <!-- 右边四个 -->
        <div class="centerright">
          <!-- 心理阅读 -->
          <div class="fourbox" style="margin-top: 0;" @click="$router.push('/classroom?type=2')">
            <div style="padding-top: 2.9rem;">心理阅读
              <div class="englishtext">Psychological Reading</div>
            </div>
            <img src="../newimg/home/homeico6.png"
              style="width: 4.4rem;height: 4.5rem;margin-top: 2.5rem;margin-left: 4.6rem;" alt="">
          </div>
          <!-- 团辅方案 -->
          <div class="fourbox" style="background-color: rgba(250, 205, 137,0.3);"
            @click="$router.push('/classroom?type=3')">
            <div style="padding-top: 2.9rem;">团辅方案
              <div class="englishtext">Group Auxiliary Plan</div>
            </div>
            <img src="../newimg/home/homeico7.png"
              style="width: 4.25rem;height: 4.75rem;margin-top: 2.6rem;margin-left: 5.56rem;" alt="">
          </div>
          <!-- 心理咨询 -->
          <div class="fourbox" @click="$router.push('/counseling')" style="background-color: rgba(156, 204, 183, 0.3);">
            <div style="padding-top: 2.9rem;">心理咨询
              <div class="englishtext">Psychological Counseling</div>
            </div>
            <img src="../newimg/home/homeico8.png"
              style="width: 5.75rem;height: 4.2rem;margin-top: 2.9rem;margin-left: 2.4rem;" alt="">
          </div>
          <!-- 心理百科 -->
          <div class="fourbox" style="background-color: rgba(136, 171, 218, 0.3);"
            @click="$router.push('/classroom?type=1')">
            <div style="padding-top: 2.9rem;">心理百科
              <div class="englishtext">Psychological Encyclopedia</div>
            </div>
            <img src="../newimg/home/homeico9.png"
              style="width: 3.9rem;height: 4.8rem;margin-top: 3rem;margin-left: 2.35rem;" alt="">
          </div>
        </div>
      </div>

    </div>

    <div style="height: 5rem;"></div>
  </div>
</template>

<script>
  import {
    Toast
  } from "vant"
  export default {
    name: "home",
    data() {
      return {
        username: '',
        showPopover: false,
        // 通过 actions 属性来定义菜单选项
        actions: [{
          id: 1,
          text: '退出登录'
        }],
      }
    },
    created() {
      this.username = JSON.parse(localStorage.getItem('userinfo')).nickname
    },
    methods: {
      onSelect(action) {
        localStorage.removeItem('token')
        localStorage.removeItem('userinfo')
        this.$router.push('/login')
        Toast(action.text);
      },

      onRefresh() {
        setTimeout(() => {
          this.isLoading = false
          this.$router.go(0)
        }, 1000)
      },
    },
  }
</script>
<style lang="less" scoped>
  .home {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 8.3rem;
    background-image: url('../newimg/homebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .firstbox {
      // font-size: 1.5rem;
      padding-top: 7.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .namebox {
        font-size: 1.525rem;


      }


    }



    .theflexbox {
      display: flex;
      align-items: center;
      // justify-content: space-between;

      margin-top: 6.8rem;
      font-size: 2.22rem;

      .centerleft {
        .xinlibox1 {
          width: 27.25rem;
          height: 16.5rem;
          background-color: rgba(157, 222, 254, 0.3);
          border-radius: 2rem;
          padding-top: 3.5rem;
        }

        .xinlibox2 {
          width: 32rem;
          height: 16.5rem;
          background-color: rgba(255, 162, 193, 0.3);
          border-radius: 2rem;
          margin-left: 2.25rem;
          padding-top: 3.5rem;
        }

        .secbox {
          width: 19rem;
          height: 18.3rem;
          background-color: rgba(172, 213, 152, 0.3);
          display: flex;
          align-items: center;
          flex-direction: column;
          border-radius: 2rem;
          padding-top: 6.2rem;
        }
      }

      .centerright {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-left: 2.3rem;
        flex-direction: column;

        .fourbox {
          display: flex;
          width: 22.5rem;
          height: 10rem;
          background-color: rgba(242, 156, 159, 0.3);
          border-radius: 2rem;
          margin-top: 2.25rem;
          padding-left: 3.5rem;
        }

      }

      .englishtext {
        font-size: 1rem;
        color: rgba(51, 35, 35, 0.5);
        margin-top: 1.1rem;
      }
    }
  }
</style>
