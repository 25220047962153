<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{list.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{list.title}}</div>
      <div class="gohome" @click="$router.push('/home')">
        <img src="../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
      </div>
    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <div class="evebox" v-for="item in list.list" @click="gothreeroom(item)">
        <img :src="$image+item.image" class="eveimgbox" alt="">
        <div>{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getClassroomAPI,
    getonlineTest
  } from "@/api/home"
  export default {
    name: "classroom",
    data() {
      return {
        list: {},
        type: ''
      }
    },
    created() {
      this.type = this.$route.query.type
      if (this.type == 8) {
        this.getcelist()
      } else {
        this.getlist()
      }

    },
    methods: {
      async getlist() {
        const params = {
          type: this.type
        }
        const res = await getClassroomAPI(params)
        this.list = res.data
      },
      async getcelist() {
        const res = await getonlineTest()
        this.list = res.data
      },
      gothreeroom(val) {
        if (this.type == 8) {
          this.$router.push({
            path: '/game',
            query: {
              id: val.id,
              type: this.type
            }
          })
        } else if (this.type == 4) {
          this.$router.push({
            path: '/threeroom',
            query: {
              id: val.id,
              type: this.type
            }
          })
        } else if (this.type == 1 && val.name == '心理图片') {
          this.$router.push({
            path: '/threeroom',
            query: {
              id: val.id,
              type: this.type
            }
          })

        } else {
          this.$router.push({
            path: '/reading',
            query: {
              id: val.id,
              type: this.type
            }
          })
        }

      }
    },
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../newimg/homebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .centerbox {
      width: 90rem;
      height: 46rem;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 2rem;
      margin-top: 3.5rem;
      display: flex;
      // align-items: center;
      // justify-content: center;
      // padding-right: 6rem;
      // padding-left: 3rem;
      flex-wrap: wrap;
      padding-bottom: 7rem;
      padding-top: 2rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0 !important;
      }


      .evebox {
        text-align: center;
        font-size: 2.222rem;
        margin-top: 4rem;
        // margin-bottom: 0;
        width: 24rem;
        margin-left: 2em;
        // background-color: red;

        .eveimgbox {
          width: 24rem;
          height: 15.5rem;
          border-radius: 1rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
</style>
