import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import axios from 'axios'
import 'vant/lib/index.css';
import {
  baseUrl
} from './utils/require.js';

Vue.prototype.$image = baseUrl
Vue.use(Vant);
Vue.config.productionTip = false
import "../public/less/media.less"
import './fontstyle/font.css'
import 'reset-css'

import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
