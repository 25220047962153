<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{maxinfo.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{maxinfo.title}}</div>
      <div style="display: flex;align-items: center;">
        <div class="gohome" @click="$router.push('/home')">
          <img src="../../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
        </div>
        <div class="goback" @click="$router.go(-1)">


          <img src="../../newimg/goback.png" style="width: 1.1rem;height: 2rem;" alt="">
        </div>
      </div>

    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <div class="picimgbox"> <img :src="$image+urlinfo.intro_image" style="width: 100%;height:100%;" alt="">
        <div class="picname">{{urlinfo.intro }} &nbsp;- &nbsp;{{ urlinfo.title}}</div>
      </div>


      <div class="listbox">
        <div class="evebox" v-for="item in list" @click="changeitem(item)">
          <img :src="$image+item.intro_image" class="eveimgbox" alt="">
        </div>
      </div>


    </div>
  </div>
</template>

<script>
  import {
    getDetailsAPI
  } from "@/api/home"
  export default {
    name: "Reading",
    data() {
      return {
        loading: false,
        finished: false,
        maxinfo: {},
        list: [],
        params: {},
        urlinfo: {}
      }
    },
    async created() {
      this.params.id = this.$route.query.detailid
      this.params.class_id = this.$route.query.id
      this.params.type = this.$route.query.type
      this.getlist()
    },
    methods: {
      settime(val) {
        let date = new Date(val * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '.';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
          '.';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        // let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        // let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        // let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        // return Y + M + D + h + m + s;
        // console.log(Y + M + D + h + m + s);
        return Y + M + D
      },
      // 获取列表
      async getlist() {
        const res = await getDetailsAPI(this.params)
        this.list = res.data.list
        this.maxinfo = res.data
        this.urlinfo = this.list[0]
      },
      changeitem(val) {
        this.urlinfo = val
      }
    },
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../../newimg/threebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .centerbox {
      width: 85rem;
      height: 55rem;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2rem;
      margin-top: 3.5rem;
      display: flex;
      justify-content: space-between;
      padding: 2.5rem;
      position: relative;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .picimgbox {
        width: 75rem;
        height: 50rem;
        position: relative;

        .picname {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 72.9rem;
          height: 4.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          font-size: 1.666rem;
          color: #ffffff;
          line-height: 4.5rem;
          padding-left: 2.1rem;
        }
      }


      .listbox {
        width: 8.5rem;
        height: 50rem;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0 !important;
        }

        .evebox {
          width: 8.5rem;
          height: 5.9rem;
          margin-bottom: 1.5rem;

          .eveimgbox {
            width: 100%;
            height: 100%;
            // border-radius: 1rem;
          }


        }

      }

    }
  }
</style>
