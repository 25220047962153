<template>

  <div class="maxbox">
    <!-- logo -->
    <div class="toplogo">
      <img src="../newimg/login/LOGO.png" style="width: 31.4rem;height: 11.2rem;" alt="">
    </div>

    <!-- 登录 -->
    <div class="theflexbox">
      <div class="centerbox">
        <div class="logintext">账 号 登 录</div>

        <!-- 账号 -->
        <div class="accbox">
          <img src="../newimg/login/acc.png" style="width: 1.4rem;height: 1.6rem;" alt="">
          <input type="text" v-model="params.account" class="inputbox" placeholder="请输入账号">
        </div>
        <!-- 密码 -->
        <div class="accbox" style="margin-top: 3rem;">
          <img src="../newimg/login/psd.png" style="width: 1.3rem;height: 1.6rem;" alt="">
          <input type="password" v-model="params.password" class="inputbox" placeholder="请输入密码">
        </div>

        <!-- 登录按钮 -->
        <div class="btnbox" @click="gologin()">
          LOGIN
        </div>

      </div>


    </div>

  </div>

</template>

<script>
  import {
    LoginAPI
  } from "@/api/login"
  import {
    Toast
  } from 'vant'
  export default {
    data() {
      return {
        params: {
          account: '',
          password: ''
        }
      }
    },
    methods: {
      // 登录
      async gologin() {
        if (!this.params.account.trim()) {
          Toast('请输入账号')
          return
        }
        if (!this.params.password.trim()) {
          Toast('请输入账号')
          return
        }
        const res = await LoginAPI(this.params)
        if (res.code == 1) {
          localStorage.setItem('token', res.data.userinfo.token)
          localStorage.setItem('userinfo', JSON.stringify(res.data.userinfo))
          Toast(res.msg)
          this.$router.push('/home')

        }


      }
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    // font-weight: bold;
    // background-color: #f7f7f7;
    background-image: url('../newimg/BG.png');
    background-size: 100% 100%;

    .toplogo {
      padding-top: 10.9rem;
      text-align: center;
    }

    .theflexbox {
      display: flex;
      justify-content: center;

      .centerbox {
        width: 45.1rem;
        height: 32.1rem;
        background-color: #ffffff;
        border-radius: 2rem;
        margin-top: 4.3rem;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .logintext {
        font-size: 2.05rem;
        padding-top: 4.35rem;
      }

      .accbox {
        width: 30rem;
        border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
        height: 3rem;
        margin-top: 3.85rem;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;

        .inputbox {
          border: 0;
          flex: 1;
          padding: 0 2rem;
          font-size: 1.11rem;
        }
      }

      .btnbox {
        width: 30rem;
        height: 3.5rem;
        background: linear-gradient(to right, rgba(74, 73, 191, 1), rgba(237, 190, 232, 1));
        border-radius: 1.675rem;
        margin-top: 4.05rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.389rem;
        color: #ffffff;
      }


    }

  }
</style>
