<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{list.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{list.title}}</div>
      <div style="display: flex;align-items: center;">
        <div class="gohome" @click="$router.push('/home')">
          <img src="../../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
        </div>
        <div class="goback" @click="$router.go(-1)">
          <img src="../../newimg/goback.png" style="width: 1.1rem;height: 2rem;" alt="">
        </div>
      </div>

    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <div class="disbox">
        <!-- 左边文字介绍 -->
        <div class="textmax">
          <div class="titlename">{{list.list.name}}</div>
          <div class="infobox" ref="infobox">

          </div>

          <!-- 小线 -->
          <div class="xianbox1"> </div>


          <div class="jiesaobox">
            {{list.list.info}}
          </div>

          <div class="liuyan" @click="godetail()">
            留言咨询
          </div>
        </div>

        <!-- 右边图片 -->
        <img :src="$image+list.list.image" class="imgbox" alt="">
      </div>

      <div class="xianbox2"></div>
    </div>
  </div>
</template>

<script>
  import {
    getCondetailAPI
  } from "@/api/home"
  export default {
    data() {
      return {
        class_id: '',
        list: []
      }
    },
    created() {
      this.id = this.$route.query.id
    },
    mounted() {
      this.getlist()
    },
    methods: {
      // 获取列表
      async getlist() {
        const params = {
          id: this.id
        }
        const res = await getCondetailAPI(params)
        this.list = res.data
        setTimeout(() => {
          let aa = document.querySelector('.infobox')
          aa.innerHTML = res.data.list.intro
        }, 100)
      },
      // 跳转详情
      godetail() {
        this.$router.push({
          path: '/message',
          query: {
            id: this.id
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../../newimg/threebg.jpg');
    background-size: 100% 100%;
    color: rgba(51, 35, 35, 1);

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .centerbox {
      width: 90rem;
      height: 50rem;
      background-color: #ffffff;
      border-radius: 2rem;
      margin-top: 3.5rem;
      padding-top: 5rem;

      .disbox {
        display: flex;
        // align-items: center;
        margin-left: 8.1rem;
        font-size: 1.4rem;

        .textmax {
          .titlename {
            padding-top: 2.8rem;
            font-size: 2.778rem;
          }

          .infobox {
            margin-top: 2rem;
            line-height: 1.5rem;
            width: 33.7rem;
            min-height: 1rem;
          }

          .xianbox1 {
            width: 2.25rem;
            height: 0.3rem;
            background: linear-gradient(to right, rgba(76, 75, 191, 1), rgba(234, 189, 231, 1));
            margin-top: 2rem;
            border-radius: 0.3rem;
          }

          .jiesaobox {
            width: 33.7rem;
            margin-top: 3rem;
            // background-color: red;
            height: 10rem;
            line-height: 2.08rem;
            font-size: 1.25rem;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }
          }

          .liuyan {
            margin-top: 2rem;
            width: 13rem;
            height: 3rem;
            background: linear-gradient(to right, rgba(76, 75, 191, 1), rgba(234, 189, 231, 1));
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: #ffffff;
            border-radius: 1.5rem;
          }

        }

        .imgbox {
          width: 36rem;
          height: 44rem;
          margin-left: 4.3rem;
          // border-radius: 1rem;
        }
      }

      .xianbox2 {
        width: 74rem;
        height: .1rem;
        margin-left: 8.1rem;
        background: linear-gradient(to right, rgba(76, 75, 191, 1), rgba(234, 189, 231, 1));
      }
    }
  }
</style>
