<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{maxinfo.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{maxinfo.title}}</div>
      <div style="display: flex;align-items: center;">
        <div class="gohome" @click="$router.push('/home')">
          <img src="../../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
        </div>
        <div class="goback" @click="$router.go(-1)">
          <img src="../../newimg/goback.png" style="width: 1.1rem;height: 2rem;" alt="">
        </div>
      </div>

    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad"
        style="display: flex; flex-wrap: wrap">
        <div class="evebox" v-for="item in list" @click="godetail(item)">
          <img :src="$image+item.intro_image" class="eveimgbox" alt="">
          <div>{{item.title}}</div>
          <div class="detailtext">
            {{item.intro}}
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import {
    getThreeroomAPI,
    getVideosAPI,
    getGamesApi,

  } from "@/api/home"
  export default {
    data() {
      return {
        loading: false,
        finished: false,
        maxinfo: {},
        list: [],
        params: {
          page: 1
        },
        type: ''
      }
    },
    async created() {
      // this.params.class_id = this.$route.query.id
      // this.params.type = this.$route.query.type
      // await this.getlist()

    },
    methods: {
      async onLoad() {
        this.loading = true
        // 如果是心理影院
        if (this.$route.query.type == 9) {
          this.type = this.$route.query.type
          await this.getVideolist()
          this.params.page++
          // 加载状态结束
          this.loading = false
        } else if (this.$route.query.type == 7) {
          this.type = this.$route.query.type
          await this.getgamelist()
          this.params.page++
          // 加载状态结束
          this.loading = false
        } else {
          this.type = this.$route.query.type
          this.params.class_id = this.$route.query.id
          this.params.type = this.$route.query.type
          await this.getlist()
          this.params.page++
          // 加载状态结束
          this.loading = false
        }

      },
      // 获取列表
      async getlist() {
        const res = await getThreeroomAPI(this.params)
        if (res.data.list.length <= 0) {
          this.finished = true
          return
        }
        this.list.push(...res.data.list)
        this.maxinfo = res.data
      },
      // 获取电影列表
      async getVideolist() {
        const res = await getVideosAPI(this.params)
        if (res.data.list.length <= 0) {
          this.finished = true
          return
        }
        this.list.push(...res.data.list)
        this.maxinfo = res.data
      },
      // 获取游戏列表
      async getgamelist() {
        const res = await getGamesApi(this.params)
        if (res.data.list.length <= 0) {
          this.finished = true
          return
        }
        this.list.push(...res.data.list)
        this.maxinfo = res.data
      },
      // 跳转详情
      godetail(val) {
        if (this.type == 9) {
          this.$router.push({
            path: '/videoplayback',
            query: {
              id: val.id,
              type: this.type
            }
          })
        } else if (this.type == 7) {
          this.$router.push({
            path: '/game',
            query: {
              id: val.id,
              type: this.type
            }
          })
        } else if (this.type == 1) {
          this.$router.push({
            path: '/Viewimage',
            query: {
              id: this.params.class_id,
              detailid: val.id,
              type: this.params.type
            }
          })
        } else {
          this.$router.push({
            path: '/videoplayback',
            query: {
              id: this.params.class_id,
              detailid: val.id,
              type: this.params.type
            }
          })

        }

      }
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../../newimg/threebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .centerbox {
      width: 87rem;
      height: 46rem;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2rem;
      margin-top: 3.5rem;
      display: flex;
      // align-items: center;
      // justify-content: center;
      // padding-right: 6rem;
      // padding-left: 3rem;
      flex-wrap: wrap;
      padding-bottom: 7rem;
      padding-top: 2rem;
      overflow-y: scroll;
      padding-left: 3rem;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .evebox {
        // text-align: center;
        font-size: 1.805rem;
        margin-left: 3rem;
        margin-top: 4rem;
        margin-bottom: 0;
        width: 24rem;

        .eveimgbox {
          width: 24rem;
          height: 15.5rem;
          border-radius: 1rem;
          margin-bottom: 1.5rem;
        }

        .detailtext {
          font-size: 1.11rem;
          margin-top: 1rem;
          line-height: 1.3rem;
          color: rgba(51, 35, 35, 0.7);
        }
      }
    }
  }
</style>
