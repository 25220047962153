import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Toast
} from 'vant'
import home from '../views/Home.vue' //首页
import login from '../views/Login.vue' //登录
import newread from '../views/Newread.vue' //心理课堂
import classroom from '../views/Classroom.vue' //心理课堂
import threeroom from '../views/componcent/Threeroom.vue' //心理课堂三级页面
import videoplayback from '../views/componcent/Videoplayback.vue' //视频播放界面
import reading from '../views/Reading.vue' //心理阅读文章列表页面
import articlereading from '../views/componcent/Articlereading.vue' //文章详情页面
import counseling from '../views/Counseling.vue' //心理咨询列表页
import threecounsel from '../views/componcent/Threecounsel.vue' //心理咨询详情页
import message from '../views/componcent/Message.vue' //心理咨询详情页
import game from '../views/componcent/Game.vue' //外部ifram
import music from '../views/Music.vue' //音乐
import viewimage from '../views/componcent/Viewimage.vue' //心理图片



Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/home'
  }, {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/classroom',
    name: 'classroom',
    component: classroom
  },
  {
    path: '/newread',
    name: 'newread',
    component: newread
  },
  {
    path: '/threeroom',
    name: 'threeroom',
    component: threeroom
  },
  {
    path: '/videoplayback',
    name: 'videoplayback',
    component: videoplayback
  },
  {
    path: '/reading',
    name: 'reading',
    component: reading
  },
  {
    path: '/articlereading',
    name: 'articlereading',
    component: articlereading
  },
  {
    path: '/counseling',
    name: 'counseling',
    component: counseling
  },
  {
    path: '/threecounsel',
    name: 'threecounsel',
    component: threecounsel
  },
  {
    path: '/message',
    name: 'message',
    component: message
  },
  {
    path: '/game',
    name: 'game',
    component: game
  },
  {
    path: '/music',
    name: 'music',
    component: music
  },
  {
    path: '/viewimage',
    name: 'viewimage',
    component: viewimage
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

const whiteList = ['/login', ]
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0, 0);
  // next()

  if (whiteList.includes(to.path)) {
    next()
  } else {
    if (localStorage.getItem('token')) {
      next()
    } else {
      Toast('你还未登陆，请先登录')
      next('/login')
    }
    next()
  }
  document.title = '智有志心理健康'
})

export default router
