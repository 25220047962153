import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: localStorage.getItem('token') || '',
		zizhanghu: ''
	},
	getters: {

	},
	mutations: {
		setzi(state, name) {
			state.zizhanghu = name
		}
	},
	actions: {},
	modules: {}
})
