<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{list.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{list.title}}</div>
      <div style="display: flex;align-items: center;">
        <div class="gohome" @click="$router.push('/home')">
          <img src="../../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
        </div>
        <div class="goback" @click="$router.go(-1)">


          <img src="../../newimg/goback.png" style="width: 1.1rem;height: 2rem;" alt="">
        </div>
      </div>

    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <video preload="auto" controls style="width: 100%;height: 100%;object-fit: fill;border-radius: 2rem;">
        <source :src="$image+list.list.video_file" type="video/mp4" style="width: 100%;height: 100%;">
        <source :src="$image+list.list.video_file" type="video/ogg" style="width: 100%;height: 100%;">
        <source :src="$image+list.list.video_file" type="video/webm" style="width: 100%;height: 100%;">
        当前浏览器不支持 video直接播放
      </video>
    </div>
  </div>
</template>

<script>
  import {
    getDetailsAPI,
    getVideosDetail
  } from "@/api/home"
  export default {
    data() {
      return {
        // url: '',
        params: {

        },
        list: {}
      }
    },
    created() {
      if (this.$route.query.type == 9) {
        this.type = this.$route.query.type
        this.params.id = this.$route.query.id
        this.getVideolist()
      } else {
        this.params.id = this.$route.query.detailid
        this.params.class_id = this.$route.query.id
        this.params.type = this.$route.query.type
        this.getlist()
      }

    },
    methods: {
      // 获取列表
      async getlist() {
        const res = await getDetailsAPI(this.params)
        this.list = res.data
        // this.url = this.$image + res.data.list.video_file
        // console.log(this.url)
      },
      // 获取电影列表
      async getVideolist() {
        const res = await getVideosDetail(this.params)
        this.list = res.data
      },
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../../newimg/threebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .centerbox {
      width: 90rem;
      height: 55rem;
      background-color: #000000;
      border-radius: 2rem;
      margin-top: 3.5rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
</style>
