import { render, staticRenderFns } from "./Reading.vue?vue&type=template&id=18c92823&scoped=true&"
import script from "./Reading.vue?vue&type=script&lang=js&"
export * from "./Reading.vue?vue&type=script&lang=js&"
import style0 from "./Reading.vue?vue&type=style&index=0&id=18c92823&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c92823",
  null
  
)

export default component.exports