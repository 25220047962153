<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{maxinfo.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{maxinfo.title}}</div>
      <div style="display: flex;align-items: center;">
        <div class="gohome" @click="$router.push('/home')">
          <img src="../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
        </div>
        <div class="goback" @click="$router.go(-1)">


          <img src="../newimg/goback.png" style="width: 1.1rem;height: 2rem;" alt="">
        </div>
      </div>

    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad"
        style="display: flex; flex-wrap: wrap">
        <div class="evebox" v-for="item in list" @click="gothreeroom(item)">
          <img :src="$image+item.intro_image" class="eveimgbox" alt="">
          <div class="textbox">
            <div class="detailtextbox">
              {{item.title}}
            </div>
            <div>
              {{item.mytime}}
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import {
    getThreeroomAPI
  } from "@/api/home"
  export default {
    name: "Reading",
    data() {
      return {
        loading: false,
        finished: false,
        maxinfo: {},
        list: [],
        params: {
          page: 1
        }
      }
    },
    async created() {
      this.params.class_id = this.$route.query.id
      this.params.type = this.$route.query.type

    },
    methods: {
      async onLoad() {
        this.loading = true
        await this.getlist()
        this.list.forEach((item) => {
          item.mytime = this.settime(item.create_time)
        })
        this.params.page++
        // 加载状态结束
        this.loading = false
      },
      settime(val) {
        let date = new Date(val * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '.';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
          '.';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        // let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        // let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        // let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        // return Y + M + D + h + m + s;
        // console.log(Y + M + D + h + m + s);
        return Y + M + D
      },
      // 获取列表
      async getlist() {
        const res = await getThreeroomAPI(this.params)
        if (res.data.list.length <= 0) {
          this.finished = true
          return
        }
        this.list.push(...res.data.list)
        this.maxinfo = res.data
      },
      gothreeroom(val) {
        this.$router.push({
          path: '/articlereading',
          query: {
            id: this.params.class_id,
            detailid: val.id,
            type: this.params.type
          }
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../newimg/threebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .centerbox {
      width: 78rem;
      height: 46rem;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2rem;
      margin-top: 3.5rem;
      display: flex;
      justify-content: space-between;
      padding: 0 5.7rem;
      flex-wrap: wrap;
      padding-bottom: 7rem;
      padding-top: 2rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .evebox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10rem;
        font-size: 2.222rem;
        margin-top: 4.5rem;



        .eveimgbox {
          width: 15rem;
          height: 10rem;
          border-radius: 1rem;
        }

        .textbox {
          width: 21.45rem;
          height: 10rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 1.38rem;
          color: rgba(51, 35, 35, 0.5);
          margin-left: 2rem;

          .detailtextbox {
            width: 21.45rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            font-size: 1.8rem;
            color: #332323;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
</style>
