<template>
  <div class="maxbox">
    <!-- 返回键 -->
    <div class="rightbox">
      <div class="gohome" @click="$router.go(-1)"><img src="../../newimg/noback.png"
          style="width: 1.9rem;height: 1.9rem;" alt=""></div>
    </div>

    <div class="titlebox">
      <div class="thetitle">{{list.list.title}}</div>
      <div class="timebox">发布时间：{{list.list.create_time}} | 来源：{{list.list.froms}} | 作者：{{list.list.author}}</div>
      <div class="needbox">

      </div>

    </div>

  </div>
</template>

<script>
  import {
    getDetailsAPI
  } from "@/api/home"
  export default {
    data() {
      return {
        params: {

        },
        list: {}
      }
    },
    created() {
      this.params.id = this.$route.query.detailid
      this.params.class_id = this.$route.query.id
      this.params.type = this.$route.query.type

    },
    mounted() {
      this.getlist()
    },
    methods: {
      settime(val) {
        let date = new Date(val * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '.';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
          '.';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        // let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        // let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        // let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        // return Y + M + D + h + m + s;
        // console.log(Y + M + D + h + m + s);
        return Y + M + D
      },
      // 获取列表
      async getlist() {
        const res = await getDetailsAPI(this.params)
        this.list = res.data
        this.list.list.create_time = this.settime(this.list.list.create_time)
        setTimeout(() => {
          // console.log(document.querySelector(".needbox"))
          document.querySelector(".needbox").innerHTML = res.data.list.content
        }, 100)

      },
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-color: rgba(251, 248, 244, 1);

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .rightbox {
      display: flex;
      justify-content: right;
      margin-top: 5.9rem;

      .gohome {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        background-color: #ffffff;
        border-radius: 1rem;

      }
    }

    .titlebox {
      margin-top: 1.35rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .thetitle {
        font-size: 2.43rem;
      }

      .timebox {
        margin-top: 2rem;
        font-size: 1.111rem;
        color: rgba(51, 35, 35, 1);
      }

      .needbox {
        width: 75rem;
        margin-top: 5.3rem;
        font-size: 1.55rem;
        line-height: 2.5rem;
        color: rgba(51, 35, 35, 1);
        padding-bottom: 5rem;
      }
    }

  }
</style>
