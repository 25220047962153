<template>
  <div class="maxbox">
    <!-- 上部分 -->
    <div class="toptitle">
      {{list.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{list.title}}</div>
      <div style="display: flex;align-items: center;">
        <div class="gohome" @click="$router.push('/home')">
          <img src="../../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
        </div>
        <div class="goback" @click="$router.go(-1)">


          <img src="../../newimg/goback.png" style="width: 1.1rem;height: 2rem;" alt="">
        </div>
      </div>

    </div>

    <div class="iframsbox">
      <iframe class="boxiframe" :src="list.list.link" frameborder="0"></iframe>
    </div>
    <div style="height: 5rem;">

    </div>

  </div>
</template>

<script>
  import {
    getIdonlineTest,
    getIdGames
  } from "@/api/home"
  export default {
    data() {
      return {
        list: {},
        params: {
          id: ''
        }
      }
    },
    created() {
      this.params.id = this.$route.query.id
      this.type = this.$route.query.type
      if (this.type == 7) {
        this.getgamelist()
      } else {
        this.getlist()
      }
    },
    mounted() {

    },
    methods: {
      async getlist() {
        const res = await getIdonlineTest(this.params)
        this.list = res.data
      },
      // 游戏
      async getgamelist() {
        const res = await getIdGames(this.params)
        this.list = res.data
      }
    }
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-color: rgba(251, 248, 244, 1);

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .goback {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 7.15rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
      padding-left: 1.35rem;
      margin-left: 2rem;
    }

    .iframsbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90rem;
      height: 55rem;
      margin-top: 2rem;

      .boxiframe {
        width: 100%;
        height: 100%;

        // &::-webkit-scrollbar {
        //   width: 0 !important;
        // }
      }
    }
  }
</style>
