import {
  request
} from "@/utils/require";

//用户登录
export function LoginAPI(data) {
  return request({
    url: 'api/user/login',
    method: 'post',
    data
  })
}
