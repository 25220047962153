<template>
  <div class="maxbox">

    <!-- 上部分 -->
    <div class="toptitle">
      {{list.title_en}}
    </div>
    <div class="flexbox" style="font-size: 3.5rem;">
      <div>{{list.title}}</div>
      <div class="gohome" @click="$router.push('/home')">
        <img src="../newimg/gohome.png" style="width: 2rem;height: 2rem;" alt="">
      </div>
    </div>

    <!-- 内容部分 -->
    <div class="centerbox">
      <!-- 上半部分 -->
      <div class="topbpxn">

        <!-- 左边 -->
        <div class="quanbox">
          <!-- 分类列表 -->
          <div class="tyepbox" @touchmove.stop>
            <div class="evetyep" @click="changetypeactive(item)" v-for="(item,index) in list.list"
              :class="{typeactive : params.class_id == item.id}">
              <img :src="$image+item.image" class="tyepimg" alt="">
            </div>
          </div>
          <!-- 中间大转盘 -->
          <div class="zhuanbox">
            <div>{{namemusic}}</div>
            <div class="musicpic">
              <img :src="$image+musicing.list.intro_image" class="bigpic" alt="">
              <img src="../newimg/music.png" class="minpic" alt="">
            </div>
          </div>
        </div>

        <!-- 右边歌曲列表 -->
        <div class="rightax">
          <!-- 标题 -->
          <div style="display: flex;align-items: center;font-size: 1.53rem;"><img src="../newimg/musictop.png"
              style="width: 1.7rem;height: 1.7rem;margin-right: 1rem;" alt="">
            <div>
              播放歌单
            </div>
          </div>

          <div class="maxmusic">
            <div class="musiceve" @click="changemusicactive(item,index)" v-for="(item,index) in musiclist.list"
              :class="{musicactive : params.id == item.id}">
              <div>{{index+1}}</div>
              <div class="namemusic">
                <div class="introbox">
                  {{item.title}}
                </div>
                <div class="peoname" :class="{nameactive : params.id == item.id}">
                  {{item.intro}}
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <!-- 音乐播放进度条 -->
      <div class="musicjindu">
        <audio id="myAudio">
          <source :src="url" type="audio/mpeg" class='musicsource'>
          <source :src="url" type="audio/ogg" class='musicsource'>
          您的浏览器不支持 audio 元素。
        </audio>
        <div class="jindubox">
          <van-slider v-model="value" active-color="#ee0a24" bar-height="0.5rem" @input="onChange">
            <template #button>
              <div class="custom-button"></div>
            </template>
          </van-slider>
        </div>
      </div>

      <!-- 名字以及控件 -->
      <div class="musicinfomax">
        <!-- 左边 -->
        <div class="musicright">
          <!-- 歌名以及歌手名字 -->
          <div class="musicname">
            <div class="ingname">
              {{musicing.list.title}}
            </div>
            <div class="gangbox"></div>
            <div class="ingpeople">{{musicing.list.intro}}</div>
          </div>

          <!-- 播放时间 -->
          <div class="musicetime">
            {{musicopen.intime}} / {{musicopen.alltime}}
          </div>
        </div>


        <!-- 右边播放控件 -->
        <div class="musicrught">
          <img src="../newimg/last.png" @click="lastmusic()" style="width: 1.65rem;height: 1.rem;" alt="">
          <!-- 暂停按钮 -->
          <img src="../newimg/wait.png" @click="getpausemusic()" v-if="musicopen.openmusic" class="openmusicbox" alt="">
          <!-- 播放按钮 -->
          <img src="../newimg/open.png" @click="getplaymusic()" v-else class="openmusicbox" alt="">
          <img src="../newimg/next.png" @click="nextmusic()" style="width: 1.65rem;height: 1.rem;" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getClassroomAPI,
    getThreeroomAPI,
    getDetailsAPI
  } from "@/api/home"
  import {
    Toast
  } from 'vant'
  export default {
    name: "Music",
    data() {
      return {
        mymusicaddress: 0, //播放到第几首
        ischange: false, // 如果修改了列表
        musicopen: {
          openmusic: false,
          alltime: '',
          intime: '',
        },
        url: '',
        value: 0,
        list: {},
        musiclist: [],
        detailmusic: {},
        musicing: {},
        type: 5,
        params: {
          class_id: '',
          id: '',
          type: 5
        },
        timeout: null,
        canmusic: true,
        namemusic: '',
      }
    },
    async created() {
      await this.getlist()
      await this.getMusic()
      this.getdetailmusic()
    },
    methods: {
      async changetypeactive(val) {
        this.namemusic = val.name
        this.mymusicaddress = 0
        this.params.class_id = val.id
        await this.getMusic()
        // this.getdetailmusic()
      },
      async changemusicactive(val, index) {
        this.mymusicaddress = index
        this.params.id = val.id
        this.getdetailmusic()
      },
      async getlist() {
        const params = {
          type: this.type
        }
        const res = await getClassroomAPI(params)
        this.list = res.data
        this.params.class_id = res.data.list[0].id
        this.namemusic = res.data.list[0].name
      },
      // 获取列表
      async getMusic() {
        const params = {
          type: 5,
          class_id: this.params.class_id
        }
        const res = await getThreeroomAPI(params)
        this.musiclist = res.data
        this.params.id = res.data.list[this.mymusicaddress].id
      },
      // 获取列表详情 切换音乐
      async getdetailmusic() {
        clearInterval(this.timeout)
        this.timeout = null
        this.canmusic = true
        const res = await getDetailsAPI(this.params)
        this.musicing = res.data
        this.url = this.$image + this.musicing.list.music_file
        await this.getmusicinfo()
      },
      // 获取音乐信息 
      getmusicinfo() {
        this.value = 0
        this.musicopen.openmusic = false
        this.musicopen.alltime = '00.00'
        this.musicopen.intime = '00.00'
        const this_ = this
        setTimeout(() => {
          // this.url = this.$image + this.musicing.list.music_file
          const res = document.getElementById("myAudio")
          res.load()
          res.oncanplay = function() {
            // 总时间
            this_.musicopen.alltime = this_.formateTime(document.getElementById("myAudio").duration)
            // 已经播放时间
            this_.musicopen.intime = this_.formateTime(document.getElementById("myAudio").currentTime)
            this_.canmusic = false
          }
        }, 100)
      },
      // 播放音乐
      getplaymusic() {
        if (this.canmusic) {
          Toast('音乐正在准备，请稍后点击播放')
          return
        }
        this.musicopen.openmusic = true
        document.getElementById("myAudio").play()
        this.timeout = setInterval(() => {
          this.musicopen.intime = this.formateTime(document.getElementById("myAudio").currentTime)
          this.value = document.getElementById("myAudio").currentTime / document.getElementById("myAudio")
            .duration * 100
        }, 1000)
      },
      // 暂停音乐
      getpausemusic() {
        this.musicopen.openmusic = false
        document.getElementById("myAudio").pause()
        clearInterval(this.timeout)
        this.timeout = null
      },
      // 移动滑块
      onChange(value) {
        let time = this.formateTime(value * document.getElementById("myAudio").duration / 100)
        let musictime = value * document.getElementById("myAudio").duration / 100
        document.getElementById("myAudio").currentTime = musictime
        this.musicopen.intime = time
        Toast(time)
      },
      // 时间转换
      formateTime(time) {
        const h = parseInt(time / 3600)
        const minute = parseInt(time / 60 % 60)
        const second = Math.ceil(time % 60)
        const hours = h < 10 ? '0' + h : h
        const formatSecond = second > 59 ? 59 : second
        return `${hours > 0 ? `${hours}:` : ''}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
      },
      // 下一首
      lastmusic() {
        if (this.mymusicaddress == 0) {
          this.mymusicaddress = this.musiclist.list.length - 1
        } else {
          this.mymusicaddress = this.mymusicaddress - 1
        }
        this.params.id = this.musiclist.list[this.mymusicaddress].id
        this.getdetailmusic()
      },
      nextmusic() {
        if (this.mymusicaddress == (this.musiclist.list.length - 1)) {
          this.mymusicaddress = 0
        } else {
          this.mymusicaddress = this.mymusicaddress + 1
        }
        this.params.id = this.musiclist.list[this.mymusicaddress].id
        this.getdetailmusic()
      }

    },
  }
</script>

<style lang="less" scoped>
  .maxbox {
    max-width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 0 6.2rem;
    background-image: url('../newimg/homebg.jpg');
    background-size: 100% 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .gohome {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: #ffffff;
      border-radius: 1rem;
    }

    .centerbox {
      width: 90rem;
      height: 55rem;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 2rem;
      margin-top: 3.5rem;
      flex-wrap: wrap;

      &::-webkit-scrollbar {
        width: 0 !important;
      }

      .topbpxn {
        display: flex;
        height: 42rem;


        .quanbox {
          width: 50.35rem;
          height: 42rem;
          display: flex;
          justify-content: space-between;
          // border: 1px #000 solid;
          background: url('../newimg/quanquan.png');
          background-size: 100% 100%;

          .tyepbox {
            height: 38.8rem;
            padding-top: 3.2rem;
            overflow-y: scroll;
            // background-color: red;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .evetyep {
              width: 12rem;
              height: 8.5rem;
              border-radius: 0px 85px 85px 0px;
              display: flex;
              align-items: center;
              justify-content: right;
              margin-top: 0.5rem;

              .tyepimg {
                width: 7.5rem;
                height: 7.5rem;
                border-radius: 50%;
                margin-right: 0.4rem;
              }
            }

            .typeactive {
              background-color: rgba(243, 213, 187, 1);
            }

          }

          .zhuanbox {
            margin-left: 8.3rem;
            margin-top: 5.9rem;
            flex: 1;
            font-size: 1.578rem;

            .musicpic {
              display: flex;
              margin-top: 3.25rem;
              align-items: center;

              .bigpic {
                width: 22.5rem;
                height: 22.5rem;
                border-radius: 2rem;
                z-index: 3;
              }

              .minpic {
                width: 18.6rem;
                height: 18.6rem;
                border-radius: 50%;
                margin-left: -11.7rem;
                z-index: 1;
              }
            }
          }
        }

        .rightax {
          margin-top: 5.7rem;
          margin-left: 6.3rem;

          .maxmusic {
            height: 33.5rem;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0 !important;
            }

            .musiceve {
              width: 27.5rem;
              height: 3.75rem;
              border-radius: 10px 0px 0px 10px;
              margin-top: 1.1rem;
              display: flex;
              font-size: 1.25rem;
              padding-top: 1rem;
              padding-left: 1.5rem;

              .namemusic {
                margin-left: 0.8rem;

                .introbox {
                  width: 25rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                }

                .peoname {
                  color: rgba(51, 35, 35, 0.5);
                  font-size: 1rem;
                  margin-top: 0.75rem;
                  width: 25rem;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .nameactive {
                  color: rgba(77, 76, 192, 0.5);
                }
              }
            }

            .musicactive {
              background: linear-gradient(to right, rgba(243, 213, 187, 1), rgba(243, 213, 187, 0));
              color: rgba(77, 76, 192, 1);
            }

          }

        }
      }

      .musicjindu {
        width: 82rem;
        position: relative;
        margin-top: 4rem;
        margin-left: 4rem;
        height: 1rem;

        .jindubox {
          width: 82rem;

          ::v-deep .van-slider__bar {
            background: linear-gradient(to right, #4D4CC0, #E9BCE7) !important;
          }

          .custom-button {
            width: 2.4rem;
            height: 1rem;
            color: #fff;
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            background-color: rgba(77, 76, 192, 1);
            border-radius: 1rem;
          }
        }


      }


      .musicinfomax {
        margin-top: 2.25rem;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        margin-left: 4rem;
        width: 78.8rem;

        padding-right: 3.2rem;

        .musicright {
          margin-top: 0.25rem;

          .musicname {
            display: flex;
            align-items: center;

            .ingname {
              font-size: 1.8rem;
              max-width: 30rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .gangbox {
              width: 0.6rem;
              height: 0.15rem;
              background-color: #332323;
              margin: 0 1.9rem;
            }

            .ingpeople {
              max-width: 18rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 1.4rem;
              color: rgba(51, 35, 35, 0.5);
            }

          }

          .musicetime {
            font-size: 1.2rem;
            color: rgba(51, 35, 35, 0.5);
            margin-top: 0.85rem;
          }
        }

        .musicrught {
          display: flex;
          align-items: center;

          .openmusicbox {
            width: 4.5rem;
            height: 4.5rem;
            margin: 0 5.4rem;
          }
        }
      }


    }
  }
</style>
